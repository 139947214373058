import { useEffect } from "react";
import { MeModel } from "../models/MeModel";
import { userApi } from "../userApi";


interface KontaktProps {
  me?: MeModel;
  }
  
  export function KontaktSectionContent({ me }: KontaktProps) {
    const [getKontakt, getKontaktState] = userApi.useLazyGetKontaktQuery();

    useEffect(() => {
      getKontakt()
    }, [me])

    return !getKontaktState.currentData ? <></> : (<div id="kontakt">
        <div className="content">
          <div id="kontakt-info" >
            <h3>
              {getKontaktState.currentData[0]}
            </h3>
            {getKontaktState.currentData.slice(1).map((l) => <p key={l} style={{margin: 0}}>{l}<br/></p>)}
            <br/>
          </div>
        </div>
      </div>);
  }