import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { baseApi } from './userApi';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const root = ReactDOM.createRoot(
  document.getElementById('content') as HTMLElement
);

const rootReducer = {
  [baseApi.reducerPath]: baseApi.reducer,
};

export const store = configureStore({
  reducer: combineReducers(rootReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
