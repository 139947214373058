import { MeModel } from "../models/MeModel";
import { VereinbarungModel } from "../models/VereinbarungModel";
import { VordienstlichesAufgebotModel } from "../models/VordienstlichesAufgebotModel";
import { userApi } from "../userApi";
import pdf from '../assets/urlaubsgesuch.pdf';
import { ReactElement } from "react";

const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

interface WkProps {
  me?: MeModel;
}

export function WkSectionContent({ me }: WkProps) {
  const { data: wkInfoData} = userApi.useGetWkInfoQuery();
  const { data: wapData } = userApi.useGetWapQuery();

  return !wkInfoData ? <></> : (<div id="wk">
        <div id="wk-inhalt" className="content">
          {me && <>
            {!me.grundFuerWkDispens && <>
              {wkInfoData.generalInfo.map((l, i) => <p key={i}>{l}<br/></p>)}
              {wapData && <div>
                Das voraussichtliche Arbeitsprogramm findest Du <a href={wapData}>hier</a>.
              </div>}
              {wkInfoData.todo.length > 0 && (<p>
                Bereite dich auf den Dienst vor und mache Meldungen, wenn nötig:
                <ul style={{marginTop: 0}}>
                  {wkInfoData.todo.map((t, i) => <li key={i}>{t}</li>)}
                </ul>
              </p>)}
              {wkInfoData.urlaubeInfo.length > 0 && (<p>
                Urlaubsgesuche:
                <ul style={{marginTop: 0}}>
                  {wkInfoData.urlaubeInfo.map((t, i) => <li key={i}><InfoItem content={t} /></li>)}
                </ul>
              </p>)}
            </>}
            <div style={{marginTop: 0, marginBottom: "50px"}}></div>
            <PersonalData me={me}/>
          </>}
        </div>
      </div>
    );
}

interface InfoItemProps {
  content: string
}

function InfoItem({ content }: InfoItemProps) {
  const { data: kontakt } = userApi.useGetKontaktQuery();

  const contentBeforeForm = content.indexOf("FORMULAR") > 0 ? content.substring(0, content.indexOf("FORMULAR")) : content;
  const contentBetween = content.indexOf("FORMULAR") > 0 && content.indexOf("ADRESSE") > 0 ? content.substring(content.indexOf("FORMULAR") + "FORMULAR".length, content.indexOf("ADRESSE")) : "";
  const contentAfterAddress = content.indexOf("ADRESSE") > 0 ? content.substring(content.indexOf("ADRESSE") + "ADRESSE".length) : "";

  return (
    <>
      {contentBeforeForm}
      {contentBetween && <a href={pdf}>dieses Formular</a>}
      {contentBetween && contentBetween}
      {contentAfterAddress && <a href={kontakt?.map(x => `mailto:${x}`).find(x => x.indexOf("@") > 0) ?? ""}>diese Adresse</a>}
      {contentAfterAddress && contentAfterAddress}
    </>
  );
}

interface PersonalDataProps {
  me: MeModel;
}

export function PersonalData({me }: PersonalDataProps) {
  return me.grundFuerWkDispens ? <DispensiertSection me={me} /> : <WkInfoSection me={me} />;
}

interface DispensiertSectionProps {
  me: MeModel;
}

function DispensiertSection({me }: DispensiertSectionProps) {
  return <>
    <Title text="Du wirst nicht aufgeboten" />
    <div>
      {"Begründung: " + me.grundFuerWkDispens}
    </div>
  </>;
}

interface WkInfoSectionProps {
  me: MeModel;
}

function WkInfoSection({me }: WkInfoSectionProps) {
  const sortAufgebote = (l: VordienstlichesAufgebotModel, r: VordienstlichesAufgebotModel) => {
    console.log(l, r, l.einruecken > r.einruecken ? 1 : l.einruecken < r.einruecken ? -1 : 0);
    return l.einruecken > r.einruecken ? 1 : l.einruecken < r.einruecken ? -1 : 0;
  }

  const aufgebote = [...me.vordienstlicheAufgebote].sort(sortAufgebote);

  return (<>
    {me && aufgebote.length > 0 && <div>
      <Title text="Geplante Marschbefehle:" />
      <div style={{marginBottom: 20, display: "flex", flexDirection: "column", gap: 15}}>
        {aufgebote.map((a, i) => <Aufgebot aufgebot={a} key={i}/>)}
      </div>
    </div>}
    {me && me.vereinbarungen.length > 0 && <div style={{marginBottom: 20}}>
      <Title text="Individuelle Vereinbarungen:" />
      <ul>
        {me.vereinbarungen.map((v) => <Vereinbarung vereinbarung={v}/>)}
      </ul>
    </div>}
  </>);
}

interface TitleProps {
  text: string
}

export function Title({ text }: TitleProps) {
  return <h4 style={{marginTop: "25px", marginBottom: "20px"}}>{text}</h4>
}

interface AufgebotProps {
  aufgebot: VordienstlichesAufgebotModel
}
  
function Aufgebot({ aufgebot }: AufgebotProps) {
  return (
    <div className="aufgebot" key={aufgebot.einruecken}>
      <div className="was">
        {aufgebot.beschrieb}
      </div>
      <div className="termin">
        <div>
          <div className="zeit">
            {new Date(aufgebot.einruecken).toLocaleDateString("de-CH", dateOptions)}
          </div>
          <div className="ort">
            {"(" + aufgebot.ortEinruecken + ")"}
          </div>
        </div>
        {(aufgebot.abtreten !== aufgebot.einruecken || aufgebot.ortAbtreten !== aufgebot.ortEinruecken) && <div>
          <div className="zeit">
            {"- " + new Date(aufgebot.abtreten).toLocaleDateString("de-CH", dateOptions)}
          </div>
          <div className="ort">
            {"(" + aufgebot.ortAbtreten + ")"}
          </div>
        </div>}
      </div>
      {aufgebot.besonderes && <div className="kommentar">
        {aufgebot.besonderes}
      </div>}
    </div>
  );
}

interface VereinbarungProps {
  vereinbarung: VereinbarungModel
}

function Vereinbarung({ vereinbarung }: VereinbarungProps) {
  return (
    <li>
      {vereinbarung.beschrieb}
    </li>
  );
}