import { FormEvent, useRef } from "react";
import { LoginModel } from "../models/LoginModel";
import { MeModel } from "../models/MeModel";


interface LoginProps {
  me?: MeModel;
  loginError?: string;
  performLogin: (loginModel: LoginModel) => void;
  performLogout: () => void;
}

export function LoginSection({me, loginError, performLogin, performLogout}: LoginProps) {
  const nameRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);
  const loggedIn = !!me;

  const login = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (nameRef.current && pwdRef.current) {
      performLogin({
        Name: nameRef.current.value.trim(),
        Pwd: pwdRef.current.value.trim()
      });
    }
  }

  return (
    <>
      {!loggedIn && <div id="login">
        <form onSubmit={login}>
          <input ref={nameRef} type="text" id="login-name" placeholder="AHV-Nr" />
          <input ref={pwdRef} type="password" id="login-pwd" placeholder="Passwort" />
          <button type="submit">
            Login
          </button>
        { loginError && <div id="login-error">
          {loginError}
        </div> }
        </form>
      </div>}

      {loggedIn && <div id="logout">
        <NameAndDays me={me} />
        <button type="button" onClick={performLogout}>
          Logout
        </button>
      </div>}
    </>
  );
}

interface NameAndDaysProps {
  me: MeModel;
}

export function NameAndDays({ me }: NameAndDaysProps) {
  return (<div style={{ textAlign: "center"}}>
    <h3>{me.grad} {me.vorname} {me.name}</h3>
    <p>{"Noch zu leistende Diensttage: " + me.verbleibendeDiensttage}</p>
  </div>);
}