import { useEffect, useState } from "react";
import { MeModel } from "../models/MeModel";
import { userApi } from "../userApi";


interface ImpressionenProps {
  me?: MeModel;
    enterFullscreen: (index: number) => void;
  }
  
  export function ImpressionenSectionContent({ me, enterFullscreen }: ImpressionenProps) {
    const images = Array.from(Array(12).keys());

    return (
      <div id="impressionen">
        <div className="content">
            <div id="images">
              {images.map((v, i) => <div key={i} onClick={() => enterFullscreen(i)}>
                <Impression me={me} index={i}/>
              </div>)}
            </div>
        </div>
      </div>
    );
  }

  interface ImpressionProps {
    me?: MeModel;
    index: number
  }

  export function Impression({ me, index }: ImpressionProps) {
    const { data } = userApi.useGetImageQuery(index + 1);

    return (
      <img src={data}/>
    );
  }