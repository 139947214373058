import { PropsWithChildren, useState } from "react";


interface CollapsableProps extends PropsWithChildren {
    title: string
  }
  
  export function CollapsableSection({ title, children }: CollapsableProps) {
    const [open, setOpen] = useState(false);
  
    const toggle = () => {
      setOpen(!open);
    }
  
    return (
      <div style={{width: "100%"}}>
        <button className={`collapsible ${open ? "active" : ""}`} onClick={toggle}>{title}</button>
        {open && children}
      </div>
    );
  }