import { useEffect } from "react";
import { JahresbriefModel } from "../models/JahresbriefModel";
import { MeModel } from "../models/MeModel";
import { userApi } from "../userApi";


interface JahresbriefProps {
  me?: MeModel;
  }
  
  export function JahresbriefSectionContent({ me }: JahresbriefProps) {
    const [getJahrebrief, getJahresbriefState] = userApi.useLazyGetJahresbriefQuery();

    useEffect(() => {
      getJahrebrief()
    }, [me])

    return !getJahresbriefState.currentData ? <></> : (<div id="jahresbrief">
          <div id="jahresbrief-inhalt" className="content">
              {getJahresbriefState.currentData.paragraphs.map((p, i) => (<p key={i}>
                {p}
              </p>))}
          </div>
        </div>
      );
  }