import { useEffect, useRef, useState } from "react";
import { MeModel } from "../models/MeModel";
import { userApi } from "../userApi";
import { AdaInsightModel } from "../models/AdaInsightModel";
import { PersonalData } from "./Wk";
import { NameAndDays } from "./Login";
import useWindowDimensions from "./useWindowDimensions";

interface AdminProps {
  me?: MeModel;
}

enum AdminMode {
  Login,
  Dispens,
  Vordienstlich,
  KVK,
  Vereinbarungen,
}

const adminModeToLabel = (mode: AdminMode): string => {
  switch (mode) {
    case AdminMode.Login:
      return "Login";
    case AdminMode.Dispens:
      return "Dispens";
    case AdminMode.Vordienstlich:
      return "Vordienstlich";
    case AdminMode.KVK:
      return "KVK";
    case AdminMode.Vereinbarungen:
      return "Vereinbarungen";
  }
}
  
export function AdminSectionContent({ me }: AdminProps) {
  const [getInsights, getInsightsState] = userApi.useLazyGetInsightsQuery();
  const [selectedAda, setSelectedAda] = useState<AdaInsightModel>();
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [mode, setMode] = useState<AdminMode>(AdminMode.Login);
  const { width } = useWindowDimensions();
  const compareDateRef = useRef<HTMLInputElement>(null);
  const compareDateInvertRef = useRef<HTMLInputElement>(null);
  const [compareDate, setCompareDate] = useState("");
  const [compareDateInverted, setCompareDateInverted] = useState(false);

  useEffect(() => {
    getInsights()
  }, [me])

  useEffect(() => {
    if (dialogRef.current?.open && !selectedAda) {
      dialogRef.current?.close()
    } else if (!dialogRef.current?.open && selectedAda) {
      dialogRef.current?.showModal()
    }
  }, [selectedAda])
  
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };

  const groupsOfAdaInsights = (insights: AdaInsightModel[], mode: AdminMode) => {
    const filteredInsights = (function() {
      switch (mode) {
        case AdminMode.Login:
          return compareDate === "" ? insights : insights.filter(x => compareDateInverted && (x.letzterLogin === null || x.letzterLogin < compareDate) || !compareDateInverted && (x.letzterLogin !== null && x.letzterLogin >= compareDate));
        case AdminMode.Dispens:
          return insights;
        case AdminMode.Vordienstlich:
        case AdminMode.KVK:
        case AdminMode.Vereinbarungen:
          return insights.filter(a => a.grundFuerWkDispens === null);
      }
    })();
    const of = filteredInsights.filter(o => o.grad === "Hptm" || o.grad === "Oblt" || o.grad === "Lt");
    const hoehUof = filteredInsights.filter(o => o.grad === "Hptfw" || o.grad === "Four" || o.grad === "Adj Uof");
    const uof = filteredInsights.filter(o => o.grad === "Wm" || o.grad === "Obwm");
    const mannschaft = filteredInsights.filter(o => !of.find(i => i == o) && !hoehUof.find(i => i == o) && !uof.find(i => i == o));
    return [
      of,
      hoehUof,
      uof,
      mannschaft
    ]
  }

  const rankToNum = (l: string): number => {
    switch (l) {
      case "Hptm":
        return 1;
      case "Oblt":
      case "Lt":
        return 2;
      case "Adj Uof":
        return 3;
      case "Four":
        return 4;
      case "Hptfw":
        return 5;
      case "Obwm":
      case "Wm":
        return 6;
      case "Obgfr":
      case "Gfr":
      case "Sdt":
        return 7;
      default:
        return 10;
    }
  }

  const sortAdas = (l: AdaInsightModel, r: AdaInsightModel) => {
    return rankToNum(l.grad) < rankToNum(r.grad) ? -1 : rankToNum(l.grad) > rankToNum(r.grad) ? 1 : l.name < r.name ? -1 : l.name > r.name ? 1 : 0
  }

  const getAdditionalInfo = (u: AdaInsightModel): string => {
    switch (mode) {
      case AdminMode.Login:
        return " (" + u.anzahlLogins + (u.anzahlLogins ? ", " + new Date(u.letzterLogin!).toLocaleDateString("de-CH", dateOptions) : "") + ")"
      case AdminMode.Dispens:
        return (u.grundFuerWkDispens ? " (" + u.grundFuerWkDispens + ")" : "") + (u.einteilung ? " (" + u.einteilung + ")" : "");
      case AdminMode.Vordienstlich:
        return u.vordienstlicheAufgebote.length > 0 ? " (" + u.vordienstlicheAufgebote.map(a => a.beschrieb).join(", ") + ")" : ""
      case AdminMode.KVK:
        return u.aufgebotFuerKvk ? " (" + u.aufgebotFuerKvk + ")" : "";
      case AdminMode.Vereinbarungen:
        return u.vereinbarungen.length > 0 ? " (" + u.vereinbarungen.map(a => a.beschrieb).join(", ") + ")" : ""
    }
  }

  const getColor = (u: AdaInsightModel): string => {
    switch (mode) {
      case AdminMode.Login:
        return u.grundFuerWkDispens ? "gray" : u.anzahlLogins > 0 ? "green" : "red";
      case AdminMode.Dispens:
        return u.grundFuerWkDispens ? "red" : u.einteilung ? "blue" : "gray";
      case AdminMode.Vordienstlich:
        return u.vordienstlicheAufgebote.length > 0 ? "blue" : "gray";
      case AdminMode.KVK:
        return u.aufgebotFuerKvk ? "blue" : "gray";
      case AdminMode.Vereinbarungen:
        return u.vereinbarungen.length > 0 ? "blue" : "gray";
    }
  }

  const getStyle = (): Object => {
    return width > 400 ? {display: "flex", flexDirection: "row", justifyContent: "stretch", width: "100%"} : {display: "flex", flexDirection: "column", justifyContent: "stretch", width: "100%"};
  }

  return !getInsightsState.currentData ? <></> : (<div id="insights">
    <div className="content">
      <div style={getStyle()}>
        {Object.keys(AdminMode).filter(v => !isNaN(Number(v))).map((v) => (
            <div style={{textAlign: "center", border: "solid 1px black", flexGrow: 1}} onClick={() => setMode(Number(v))}>
              {adminModeToLabel(Number(v))}
            </div>
            ))}
      </div>
      {mode === AdminMode.Login && (<div>
        <input ref={compareDateRef} type="text" id="login-since" placeholder="letzter Login nach" onChange={() => { setCompareDate(compareDateRef?.current?.value ?? "") }}/>
        <label><input ref={compareDateInvertRef} type="checkbox" id="no-login-since" checked={compareDateInverted} onClick={() => { setCompareDateInverted(!compareDateInverted) }}/>invertieren</label>
      </div>)}
      {groupsOfAdaInsights(getInsightsState.currentData.adaInsights, mode).map((g, i) => (
        <ul key={i}>
          {g.sort(sortAdas).map((u) => (
            <li key={u.name + u.vorname} onClick={() => setSelectedAda(u)} style={{color: getColor(u)}}>
              {u.grad + " " + u.name + ", " + u.vorname + getAdditionalInfo(u)}
            </li>
          ))}
        </ul>
      ))}
      <dialog ref={dialogRef} onClick={() => setSelectedAda(undefined)}>
        {selectedAda && (<div>
            <NameAndDays me={selectedAda}/>
            <PersonalData me={selectedAda}/>
          </div>)}
      </dialog>
    </div>
  </div>);
}