import { useEffect, useState } from "react";
import "./App.css";
import { LoginModel } from "./models/LoginModel";
import { Overlay } from "./components/Overlay";
import { LoginSection } from "./components/Login";
import { CollapsableSection } from "./components/Collapsible";
import { JahresbriefSectionContent } from "./components/Jahresbrief";
import { ImpressionenSectionContent } from "./components/Impressionen";
import { KontaktSectionContent } from "./components/Kontakt";
import { AdminSectionContent } from "./components/Admin";
import { userApi } from "./userApi";
import { WkSectionContent } from "./components/Wk";


function App() {
  const [loginError, setLoginError] = useState<string>();
  const [fullscreenIndex, setFullscreenIndex] = useState<number | undefined>(undefined);

  const [getMe, getMeState] = userApi.useLazyGetMeQuery();

  const login = (loginModel: LoginModel) => {
    setLoginError(undefined);
    logout();
    fetch("/api/user/login", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(loginModel),
    })
      .then((resp) => {
          if (resp?.ok) {
            setLoginError(undefined);
            // refresh();
          } else {
            setLoginError("Die Benutzerdaten sind falsch");
          }
          getMe();
      });
  };

  const logout = () => {
    fetch("/api/user/logout", {
        method: "POST"
    })
        .then(() => {
          getMe();
        });
  };

  const enterFullscreen = (index: number) => {
    setFullscreenIndex(index);
  }

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      <Overlay me={getMeState.data} index={fullscreenIndex} setIndex={setFullscreenIndex} />
      <LoginSection me={getMeState.data} loginError={loginError} performLogin={login} performLogout={logout}/>
      {!!getMeState.data && <>
        <CollapsableSection title={"Dein WK 2024"}>
          <WkSectionContent me={getMeState.data}/>
        </CollapsableSection>
        <CollapsableSection title={"Jahresbrief 2024"}>
          <JahresbriefSectionContent me={getMeState.data}/>
        </CollapsableSection>
        <CollapsableSection title={"Impressionen WK 2023"}>
          <ImpressionenSectionContent me={getMeState.data} enterFullscreen={enterFullscreen}/>
        </CollapsableSection>
        <CollapsableSection title={"Kontakt"}>
          <KontaktSectionContent me={getMeState.data}/>
        </CollapsableSection>
        {getMeState.data.grad === "Hptm" &&
          <CollapsableSection title={"Admin-Insights"}>
            <AdminSectionContent me={getMeState.data}/>
          </CollapsableSection>
        }
      </>}
    </>
  );
}

export default App;