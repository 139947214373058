
import { useCallback, useEffect } from 'react';
import arrowLeft from '../assets/arrow left.png';
import arrowRight from '../assets/arrow right.png';
import { MeModel } from '../models/MeModel';
import { Impression } from './Impressionen';

interface OverlayProps {
    me?: MeModel,
    index: number | undefined,
    setIndex: (newIndex: number | undefined) => void;
  }
  
  export function Overlay({ me, index, setIndex }: OverlayProps) {
    const escape = () => {
        setIndex(undefined)
    };

    const left = () => {
        if (index !== undefined && index > 0) setIndex(index - 1)
    }

    const right = () => {
        if (index !== undefined && index < 11) setIndex(index + 1)
    }

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (index !== undefined) {
            if (event.key === "Escape") {
                escape();
            }
            if (event.key === "ArrowLeft") {
                left();
            }
            if (event.key === "ArrowRight") {
                right();
            }
        }
    }, [index]);

    useEffect(() => {
      document.addEventListener("keydown", escFunction, false);
  
      return () => {
        document.removeEventListener("keydown", escFunction, false);
      };
    }, [escFunction]);

    if (index === undefined) {
      return null;
    }
  
    return (
      <div id="overlay" style={{display: index === undefined ? "none" : "block"}}>
        <div id="overlay_img_container" onClick={escape}>
          <Impression index={index} />
        </div>
        {index !== undefined && index > 0 && <div id="overlay_arrow_left">
          <img src={arrowLeft} onClick={left} />
        </div>}
        {index !== undefined && index < 11 && <div id="overlay_arrow_right">
          <img src={arrowRight} onClick={right} />
        </div>}
      </div>
    );
  }