import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { MeModel } from './models/MeModel';
import { JahresbriefModel } from './models/JahresbriefModel';
import { AdminInsightsModel } from './models/AdminInsightsModel';
import { WkInfoModel } from './models/WkInfoModel';

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/user/' }),
    endpoints: () => ({}),
});

const withTags = baseApi.enhanceEndpoints({
    addTagTypes: ['UserData'],
});

export const userApi = withTags.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query<MeModel, void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: 'me',
            }),
            providesTags: ['UserData'],
        }),
        getJahresbrief: builder.query<JahresbriefModel, void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: 'jahresbrief',
            }),
            providesTags: ['UserData'],
        }),
        getKontakt: builder.query<string[], void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: 'kontakt',
            }),
            providesTags: ['UserData'],
        }),
        getInsights: builder.query<AdminInsightsModel, void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: 'insights',
            }),
            providesTags: ['UserData'],
        }),
        getImage: builder.query<string, number>({
            query: (index) => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: `image/${index}`,
                responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
            }),
            providesTags: ['UserData'],
        }),
        getWkInfo: builder.query<WkInfoModel, void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: `wk/info`,
            }),
            providesTags: ['UserData'],
        }),
        getWap: builder.query<string, void>({
            query: () => ({
                method: 'get',
                credentials: "include" as RequestCredentials,
                url: `wk/wap`,
                responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
            }),
            providesTags: ['UserData'],
        }),
    }),
});
